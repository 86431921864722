/*  ==========================================================================
    FOOTER V3
    ========================================================================== */

@mixin footer--v3() {
  padding-bottom: calc(#{$unit__hgt--footer-fixed-bar} + 60px);
  // footer top
  .bn-f-top {
    background-color: $clr__ci--primary;
    color: $clr__typo--secondary;
    padding: 40px 0;
    a {
      color: $clr__typo--secondary;
      &:visited {
        color: $clr__typo--secondary;
      }
      &:hover {
        color: rgba($clr__typo--secondary, 0.5);
      }
    }
    .bn-f-top__weather,
    .bn-f-top__map-trigger {
      padding-top: 20px;
    }
  }
  // footer center
  .bn-f-center {
    background-color: rgba($clr__ci--primary,0.1);
    padding: 40px 0;
  }
  // footer bottom
  .bn-f-bottom {
    padding-top: 40px;
    a {
      color: $clr__typo--primary;
      &:visited {
        color: $clr__typo--primary;
      }
      &:hover {
        color: $clr__ci--primary;
      }
    }
    .bn-f-bottom__partners {
      padding-top: 40px;
    }
    .bn-f-bottom__useful-sites {
      padding-top: 60px;
    }
  }
  // address
  .bn-address {
    &.bn-address--v2 {
      font-size: 1.6em;
      font-style: normal;
      text-align: center;
      .bn-address__item {
        &.bn-address__item--de,
        &.bn-address__item--en,
        &.bn-address__item--it {
          display: none;
        }
        &.bn-address__company,
        &.bn-address__tel,
        &.bn-address__mail {
          font-weight: $typo__fw--bold;
        }
      }
      &.bn-address--de {
        .bn-address__item {
          &.bn-address__item--de {
            display: inline;
          }
        }
      }
      &.bn-address--en {
        .bn-address__item {
          &.bn-address__item--en {
            display: inline;
          }
        }
      }
      &.bn-address--it {
        .bn-address__item {
          &.bn-address__item--it {
            display: inline;
          }
        }
      }
    }
  }
  // weather teaser
  .bn-weather-teaser {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.6em;
    justify-content: center;
    .bn-weather-teaser__title,
    .bn-weather-teaser__forecast {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .bn-weather-teaser__title {
      margin-right: 5px;
    }
    .bn-weather-teaser__forecast {
      .bn-loader {
        color: $clr__typo--secondary;
        font-size: 1.0em;
      }
      .weather-icon {
        font-size: 1.3em;
        padding: 0 5px;
      }
    }
  }
  // map trigger
  .bn-map-trigger {
    font-size: 1.6em;
    text-align: center;
    .bn-map-trigger__link {
      color: $clr__typo--secondary;
      letter-spacing: 1px;
      text-decoration: underline;
      text-transform: uppercase;
      &:visited {
        color: $clr__typo--secondary;
      }
      &:hover {
        color: rgba($clr__typo--secondary, 0.5);
      }
    }
  }
  // newsletter
  .bn-newsletter {
    .bn-newsletter__title {
      font-size: 1.6em;
      text-transform: uppercase;
    }
  }
  // references
  .bn-references {
    font-size: 3.0em;
    text-align: center;
    a {
      color: $clr__ci--primary;
      &:visited {
        color: $clr__ci--primary;
      }
      &:hover {
        color: $clr__ci--secondary;
      }
    }
    .bn-references__item {
      display: inline-block;
      margin-top: 20px;
      padding: 0 20px;
    }
  }
  // legal menu
  .bn-legal-menu {
    &.bn-legal-menu--v2 {
      @include clearfix();
      font-size: 1.4em;
      .bn-break {
        display: none;
      }
      .bn-legal-menu__item {
        &.bn-legal-menu__item--cur {
          color: $clr__ci--primary;
        }
      }
      .bn-powered-by {
        color: $clr__black;
        display: inline-block;
        float: right;
        &:visited {
          color: $clr__black;
        }
        &:hover {
          color: $clr__ci--primary;
        }
      }
    }
  }
  // partners
  .bn-partners {
    text-align: center;
    .bn-partners__item {
      display: inline-block;
      margin-top: 20px;
      padding: 0 40px;
      img {
        @include img--rendering-crisp-edges();
        height: 80px;
        width: auto;
      }
    }
  }
  // useful sites
  .bn-useful-sites {
    font-size: 1.4em;
    text-align: center;
    a {
      color: $clr__typo--primary;
      .bn-useful-sites__comma {
        color: $clr__typo--primary;
      }
      &:visited {
        color: $clr__typo--primary;
      }
      &:hover {
        color: $clr__ci--primary;
      }
    }
    .bn-useful-sites__title {
      display: inline-block;
      font-weight: $typo__fw--bold;
      margin-right: 5px;
    }
  }
  // view switcher
  .bn-view-switcher {
    display: none !important; //remove !important if you want enable version switcher
    font-size: 1.4em;
    font-style: italic;
    padding-top: 60px;
    text-align: center;
    text-transform: uppercase;
    a {
      color: $clr__typo--primary;
      &:visited {
        color: $clr__typo--primary;
      }
      &:hover {
        color: $clr__ci--primary;
      }
    }
    .bn-view-switcher__trigger {
      &.bn-view-switcher__trigger--web {
        display: none;
      }
    }
    &.bn-view-switcher--view-switched {
      display: block;
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // desktop medium
  @media #{$mq__desktop--medium} {
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 70px;
        }
      }
    }
  }
  // desktop small
  @media #{$mq__desktop--small} {
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 60px;
        }
      }
    }
  }
  // laptop
  @media #{$mq__laptop} {
    // partners
    .bn-partners {
      .bn-partners__item {
        padding: 0 20px;
        img {
          height: 50px;
        }
      }
    }
  }
  // tablet
  @media #{$mq__tablet} {
    padding-bottom: 130px;
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 40px;
        }
      }
    }
    // useful pages
    .bn-useful-sites {
      .bn-useful-sites__title {
        display: block;
        margin-right: 0;
      }
    }
    // view switcher
    .bn-view-switcher {
      display: block;
      .bn-view-switcher__trigger {
        &.bn-view-switcher__trigger--mobile {
          display: none;
        }
        &.bn-view-switcher__trigger--web {
          display: inline;
        }
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    // weather teaser
    .bn-weather-teaser {
      display: block;
      .bn-weather-teaser__title {
        display: block;
        margin-right: 0;
        text-align: center;
      }
      .bn-weather-teaser__forecast {
        justify-content: center;
      }
    }
    // legal menu
    .bn-legal-menu {
      &.bn-legal-menu--v2 {
        text-align: center;
        .bn-pipe {
          display: none;
        }
        .bn-break {
          display: inline;
        }
        .bn-powered-by {
          float: none;
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    // weather teaser
    .bn-weather-teaser {
      font-size: 1.4em;
    }
    // partners
    .bn-partners {
      .bn-partners__item {
        img {
          height: 30px;
        }
      }
    }
  }
}
